@import 'scss/colors';

.add-overlay-page-dialog {
  .mdc-dialog__surface {
    padding: 0;
    background-color: $bg-opacity-80 !important;
    backdrop-filter: blur(0.75rem);
    box-shadow: none !important;
  }

  .overlay-page {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    // padding-top: 80px;

    .body-info {
      flex: 1 1 0%;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 52rem;
      margin: 0 auto;
      padding: 1rem 1rem 2rem;
      box-sizing: border-box;

      .form {
        width: 100%;
      }

      .header-cont {
        margin-bottom: 2rem;

        .header {
          text-align: left;
          margin: 0;
        }

        .subtitle {
          margin-top: 0.5rem;
          color: $text-secondary;
          font-size: 1.125rem;
          line-height: 1.44;
        }
      }

      .body-header {
        font-size: 1.125rem;
        line-height: 1.44;
        color: $text-primary;
        margin-bottom: 0.625rem;
      }
    }

    app-add-space-header {
      display: block;
      width: 100%;
    }

    app-add-space-navigation {
      position: sticky;
      bottom: 0;
      display: block;
      width: 100%;
    }
  }
}
