@import 'scss/colors';

::ng-deep .overlay-page-dialog {
  .mdc-dialog__surface {
    padding: 0;
    background-color: $overlay-bg !important;
    box-shadow: none !important;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flex-left-placeholder {
      width: 35px;
      position: fixed;
    }

    .title {
      //font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      //letter-spacing: 3.6px;
      color: $eventThemePrimaryColor;
      text-align: center;
      //text-transform: uppercase;
      //margin: 0;
      //padding: 0 10px;
      opacity: 50%;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    padding-top: 47px;
    padding-bottom: 50px;
  }

  .body-info {
    display: flex;
    justify-content: center;
    width: 100%;

    .body-header {
      font-weight: bold;
      font-size: 18px;
      line-height: 1.67;
      letter-spacing: 2.7px;
      color: $text-dark;
      margin: 0;
      padding: 0;
      margin-bottom: 15px;
      text-align: left;
      text-transform: uppercase;
    }

    .body-subheader {
      font-size: 16px;
      text-transform: none;
      color: $text-dark;
    }

    .body-header-cont {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 14px;
      margin-bottom: 15px;

      .body-header {
        margin-bottom: 0;
      }

      .body-header-info {
        font-size: 12px;
        line-height: 2.5;
        letter-spacing: 1.8px;
        color: $text-brand-primary;
        font-weight: bold;
      }
    }
  }

  ::ng-deep .mdc-dialog__title::before {
    display: unset;
  }

  .fa-times-circle::before {
    color: $text-brand-primary;
    font-size: 35px;
    cursor: pointer;
  }
}

::ng-deep .overlay-page-preview-dialog {
  .mdc-dialog__surface {
    padding: 0;
    background-color: $overlay-bg !important;
    box-shadow: none !important;
  }
}

// create step header
.create-step-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  padding: 0 23px;
  background-color: $body-bg;

  .flex-left-placeholder {
    width: 280px;
  }

  .header-title-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .header-info {
      font-size: 10px;
      color: $eventThemePrimaryColor;
      text-align: center;
      letter-spacing: 1.3px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .event-date-info {
      margin-left: 4px;
    }
  }

  .btn {
    width: 280px;
  }

  .info-box-cont {
    position: absolute;
    left: -253px;
    top: 0;
    max-width: 213px;
    opacity: 0;

    transition: all 0.3s;

    &.show-info-box {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .create-step-header {
    .btn,
    .flex-left-placeholder {
      width: 200px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .create-step-header {
    justify-content: center;
    height: 64px;
    padding: 0 19px;

    .header-title-cont {
      h1 {
        font-size: 18px;
        line-height: 1.33;
      }
    }

    .btn {
      position: fixed;
      bottom: 36px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      width: 280px;
    }

    .info-box-cont {
      left: auto;
      top: 320px;
    }
  }
}
