@import "scss/colors";
@import "scss/typography";

.selected-or-hover {
  background-color: $filter-selected-status !important;
  border-radius: 10px !important;
}

.autocomplete-or-select-panel {
  border-radius: 10px !important;
  max-height: 250px !important;
  overflow: auto;
  border: solid 1px $grey-e1e1e1;
  box-shadow: none !important;

  margin: 0;
  padding: 10px 20px 10px 13px !important;
  list-style-type: none;

  ::ng-deep .mat-mdc-option {
    @extend .placeholder;
  }
}
