.add-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 25px;
  position: fixed;
  bottom: 69px;
  left: 50%;
  z-index: 2;

  @media only screen and (max-width: 768px) {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
}

.actions {
  @include font;
  //letter-spacing: 3.3px;
  color: $text-btn;
  text-align: center;
}

.actionButton {
  border-radius: 25px;
  box-sizing: border-box;
  white-space: nowrap;
  min-width: fit-content;
  box-shadow: none !important;
}

.addButton {
  background-color: $brand-primary !important;
  color: $text-on-primary !important;
}
