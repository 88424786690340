@import './colors';

// var fonts
:root {
  --primary-font-family: 'Gabarito';
  --signal-font-family: 'Gabarito';
  --body-font-family: 'Gabarito';
  --body-font-weight: 'normal';
}

$primaryFontFamily: var(--primary-font-family);
$signalFontFamily: var(--signal-font-family);
$bodyFontFamily: var(--body-font-family);
$bodyFontWeight: var(--body-font-weight);

h1 {
  font-size: 32px;
  line-height: 1.41;
  font-weight: normal;

  margin-top: 2px;
  margin-bottom: 7px;

  text-align: center;
}

h4 {
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.62;
}

.subheader {
  color: $body-color;
  line-height: 1.88;
  height: 171px;
  text-align: center;

  &.subheader-sm {
    min-height: 52px;
    height: auto;
    padding-bottom: 20px;
  }
}

p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.text-brand-primary {
  color: $text-brand-primary;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-sm {
  font-size: 12px;
  line-height: 2.08;
}

.text-primary {
  color: $eventThemePrimaryColor;
}

.icon-check {
  color: $user-check;
}

.text-success {
  color: $success;
}

.text-danger {
  color: $danger;
}

.text-grey {
  color: $text-grey;
}

.text-dark-grey-50-opacity {
  color: $filter-bg-dark;
  opacity: 50%;
}

.text-warning {
  color: $info-box;
}

.font-weight-bold {
  font-weight: bold;
}

@mixin darker-grey-text {
  color: $text-grey-darker !important;
}

@mixin font-size-12 {
  font-size: 12px !important;
}

@mixin font {
  font-family: 'Gabarito', sans-serif !important;
  font-size: 14px !important;
  line-height: 25px !important;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  //color: $text-dark;
}

.placeholder {
  color: $text-dark !important;
  @include font;
}

.primary-font {
  font-family: $primaryFontFamily;
  color: $eventThemePrimaryColor;
}

.signal-font {
  font-family: $signalFontFamily;
  color: $eventThemeSignalColor;
}

.body-font {
  font-family: $bodyFontFamily;
  color: $eventThemeBodyFontColor;
  font-weight: $bodyFontWeight;
}

.community-body-font {
  font-family: $bodyFontFamily;
  // font-weight: $bodyFontWeight;
  color: $community-body-color;
}

.community-body-font-only {
  font-family: $bodyFontFamily;
}

.community-primary-font {
  font-family: $primaryFontFamily;
  color: $community-title-color;
}

.community-primary-font-only {
  font-family: $primaryFontFamily;
}

p {
  &.ql-align-center {
    text-align: center;
  }
  &.ql-align-right {
    text-align: end;
  }
  &.ql-align-justify {
    text-align: justify;
  }
}

// system-wide typography
.h1 {
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
}

.h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.31;
}

.h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.44;
}

.h5 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

.h6 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.42;
}

.h7 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
}

.p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.p-alt {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
}

.p1 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33;
}

.p2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.33;
}

@media only screen and (max-width: 768px) {
  .h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
  }

  .h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.33;
  }

  .h3 {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.45;
  }

  .h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.33;
  }

  .h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
  }

  .h6 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
  }

  .h7 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }

  .p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }

  .p-alt {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
  }

  .p1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42;
  }

  .p2 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.33;
  }
}
