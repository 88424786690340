@import "scss/colors";

.page-header {
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 2px;
  color: $text-dark;
  text-align: left;
  text-transform: uppercase;
}

.hr {
  border: 1px solid $text-grey;
  margin: 13px 0 0 0;
}
