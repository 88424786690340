// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import 'scss/colors';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-brand-primary: (
  50: $brand-primary,
  100: $brand-primary,
  200: $brand-primary,
  300: $brand-primary,
  400: $brand-primary,
  500: $brand-primary,
  600: $brand-primary,
  700: $brand-primary,
  800: $brand-primary,
  900: $brand-primary,
  A100: $brand-primary,
  A200: $brand-primary,
  A400: $brand-primary,
  A700: $brand-primary,
  contrast: (
    50: $grey-light,
    100: $grey-light,
    200: $grey-light,
    300: $grey-light,
    400: $grey-light,
    500: $grey-very-light,
    600: $grey-very-light,
    700: $grey-very-light,
    800: $grey-very-light,
    900: $grey-very-light,
    A100: $grey-light,
    A200: $grey-very-light,
    A400: $grey-very-light,
    A700: $grey-very-light,
  ),
);

$mat-brand-danger: (
  50: $danger,
  100: $danger,
  200: $danger,
  300: $danger,
  400: $danger,
  500: $danger,
  600: $danger,
  700: $danger,
  800: $danger,
  900: $danger,
  A100: $danger,
  A200: $danger,
  A400: $danger,
  A700: $danger,
  contrast: (
    50: $grey-light,
    100: $grey-light,
    200: $grey-light,
    300: $grey-light,
    400: $grey-light,
    500: $grey-very-light,
    600: $grey-very-light,
    700: $grey-very-light,
    800: $grey-very-light,
    900: $grey-very-light,
    A100: $grey-light,
    A200: $grey-very-light,
    A400: $grey-very-light,
    A700: $grey-very-light,
  ),
);

$eventpage-ai-front-primary: mat.define-palette($mat-brand-primary);
$eventpage-ai-front-accent: mat.define-palette($mat-brand-primary);

// The warn palette is optional (defaults to red).
$eventpage-ai-front-warn: mat.define-palette($mat-brand-danger);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$eventpage-ai-front-theme: mat.define-light-theme(
  (
    color: (
      primary: $eventpage-ai-front-primary,
      accent: $eventpage-ai-front-accent,
      warn: $eventpage-ai-front-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($eventpage-ai-front-theme);

/* Material custom styles */
.mat-mdc-form-field:not(.mat-form-field-appearance-fill) {
  @include mat.all-component-densities(-1.5);
}

:root {
  --mdc-dialog-container-shape: 12px;
}

.mat-mdc-form-field-infix {
  min-height: 50px;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  display: flex;
  padding-top: 13px;
  padding-bottom: 13px;
  color: $matFormFieldInputColor;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined:not(
    .mdc-text-field--no-label
  ) {
  .mat-mdc-form-field-infix {
    padding-top: 18px;
    padding-bottom: 8px;
  }

  .mat-mdc-select-arrow {
    margin-top: -8px;
  }
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 24px;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 6px 0 0 !important;

  .mat-mdc-form-field-error {
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 1.7;
    text-align: center;
  }
}

.mat-mdc-form-field {
  font-size: 14px !important;
  line-height: 20px !important;
  margin-bottom: 20px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  color: $matFormFieldInputColor !important;

  &::placeholder {
    color: $matFormFieldLabelColor !important;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-floating-label--float-above {
  color: $matFormFieldLabelColor !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label--float-above {
  color: $matFormFieldLabelFocusedColor !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled {
  .mdc-text-field__input {
    color: $matFormFieldDisabledInputColor !important;

    &::placeholder {
      color: $matFormFieldDisabledInputColor !important;
    }
  }

  .mdc-floating-label,
  .mdc-floating-label--float-above {
    color: $matFormFieldDisabledLabelColor !important;
  }

  .mat-mdc-form-field-icon-suffix {
    color: $matFormFieldDisabledLabelColor !important;
  }
}

mat-form-field.ng-invalid.ng-touched {
  animation: bigger;
  animation-duration: 0.3s;
}

@keyframes bigger {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: 20px;
  }
}

// mat-form-field.ng-valid.ng-touched {
//   animation: smaller;
//   animation-duration: 0.3s;
// }

// @keyframes smaller {
//   from {
//     margin-bottom: 40px;
//   }
//   to {
//     margin-bottom: 20px;
//   }
// }

.mdc-text-field--outlined {
  background-color: $matFormFieldBackgroundColor;
  border-radius: 12px !important;
}
.mat-focused .mdc-text-field--outlined,
.mat-mdc-form-field.has-value .mdc-text-field--outlined {
  background-color: $matFormFieldFocusedBackgroundColor;
}

.mat-mdc-form-field:not(.mat-form-field-appearance-fill)
  .mat-mdc-floating-label.mdc-floating-label--float-above {
  transform: translateY(-21.75px) scale(0.75) !important;
}

/* Set top-lef and bottom-left corner radius */
.mdc-notched-outline__leading {
  border-radius: 12px 0 0 12px !important;
  min-width: 12px !important;
}

/* Set top-right and bottom-right corner radius */
.mdc-notched-outline__trailing {
  border-radius: 0 12px 12px 0 !important;
}

.mdc-notched-outline__notch {
  border-top: 1px solid !important;
  border-left: none !important;
}

.mdc-notched-outline > * {
  border-color: $matFormFieldBorderColor !important;
}
.mat-mdc-form-field.has-value:not(.ng-invalid) {
  .mdc-notched-outline {
    > * {
      border-color: $matFormFieldFocusedBorderColor !important;
    }

    .mdc-notched-outline__notch {
      border-top: 1px solid !important;
      border-color: $matFormFieldFocusedBorderColor !important;
    }
  }

  .mdc-text-field--focused {
    .mdc-notched-outline__notch {
      border-top: 2px solid !important;
      border-color: $matFormFieldFocusedBorderColor !important;
    }
  }
}
.mdc-text-field--focused {
  .mdc-notched-outline {
    > * {
      border-color: $matFormFieldFocusedBorderColor !important;
    }

    .mdc-notched-outline__notch {
      border-top: 2px solid !important;
      border-color: $matFormFieldFocusedBorderColor !important;
    }
  }
}
.mdc-text-field--invalid {
  .mdc-notched-outline {
    > * {
      border-color: $danger !important;
    }

    .mdc-notched-outline__notch {
      border-top: 1px solid !important;
      border-color: $danger !important;
    }
  }
}

.mat-mdc-form-field-text-suffix {
  flex-shrink: 0;
  padding-left: 4px;
  align-self: center;
}

.mdc-text-field--focused.mdc-text-field--invalid {
  .mdc-notched-outline__notch {
    border-top: 2px solid !important;
    border-color: $danger !important;
  }
}
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}
div.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
  background: $body-bg;
  color: $body-color;
}
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
  border-radius: 10px !important;
  max-height: 250px !important;
  overflow: auto;
  border: solid 1px $border-color;
  box-shadow: none !important;
  background: $matFormFieldFocusedBackgroundColor !important;

  margin: 0;
  padding: 10px 13px !important;
  list-style-type: none;

  position: absolute;
  top: 100%;
  left: 0;

  .mat-mdc-option {
    padding-left: 7px;
    padding-right: 8px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    .mat-pseudo-checkbox {
      display: none;
    }

    &.reset-value-option {
      .mdc-list-item__primary-text {
        color: $grey-979797 !important;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 6.6px;
    height: 46.8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey-e1e1e1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    width: 6.6px;
  }
}

.mat-mdc-option.mat-mdc-option-active {
  &:not(.mdc-list-item--selected):not(:hover) {
    background-color: $matFormFieldBackgroundColor !important;
    border-radius: 10px !important;
  }
}

.mat-mdc-option.mdc-list-item--selected,
.mat-mdc-option:hover {
  background-color: $matFormFieldBackgroundColor !important;
  border-radius: 10px !important;
}

.autocomplete-dropdown-icon {
  padding-right: 18px;
  color: $text-primary;
}

.clear-suffix-icon {
  padding: 10px 16px;
}

.autocomplete-list-chip {
  background-color: $body-bg !important;
  font-size: 14px;
  height: 35px !important;
  color: $text-dark;
}

.autocomplete-select-chip-set:has(.mat-mdc-chip) {
  margin-bottom: 20px;
}

.mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
  padding-left: 7px !important;
  padding-right: 8px !important;
}
.mat-mdc-optgroup-label {
  @extend .p1;
  color: $text-secondary;

  padding-left: 7px !important;
  padding-right: 8px !important;
  min-height: 40px;
}

.mat-datepicker-content {
  box-shadow: none !important;
  border-radius: 10px !important;
  border: solid 1px $grey-e1e1e1;
}

// mat-tab
.mat-mdc-tab {
  .mdc-tab__text-label {
    color: $body-color !important;
  }
  .mdc-tab-indicator__content--underline {
    border-color: $text-brand-primary;
  }
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: $body-color !important;
}

.new-tabs {
  .mat-mdc-tab-header {
    width: 100%;
    padding: 0 0.5rem;
    box-sizing: border-box;
  }

  .mat-mdc-tab-labels {
    gap: 0.5rem;
  }
  .mat-mdc-tab {
    min-width: unset;
    height: 40px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;

    @extend .h6;

    &.mdc-tab--active .mdc-tab__text-label {
      color: $text-primary !important;
    }
    .mdc-tab__text-label {
      color: $text-secondary !important;
    }
    .mdc-tab-indicator__content--underline {
      border-color: $text-brand-primary;
    }
  }
}

// mat-calendar
mat-calendar {
  background-color: $form-background !important;

  .mat-calendar-body-in-range {
    span {
      color: $const-dark-color !important;
    }

    &.mat-calendar-body-range-start,
    &.mat-calendar-body-range-end {
      span {
        color: $matCalendarSelectedDate !important;
      }
    }
  }

  .mat-calendar-body-label {
    color: $body-color !important;
    opacity: 0 !important;
  }

  .mat-calendar-body-label[colspan='7'] {
    display: none;
  }

  span {
    color: $body-color !important;
  }

  .mat-calendar-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    color: $mat-datepicker-disabled-date-label !important;
  }
}

.mat-datepicker-toggle {
  color: $matFormFieldLabelColor !important;
}

.mat-datepicker-actions {
  background-color: $form-background;

  .mat-mdc-button:not(:disabled) {
    color: $body-color;
  }
}

.event-theme-calendar {
  .mat-calendar-body-selected {
    background-color: $mat-calendar-selected !important;
    color: $matCalendarSelectedDate !important;
  }
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: $mat-calendar-hover !important;
    color: $const-dark-color !important;
  }

  .mat-calendar-body-in-range::before {
    background-color: $mat-calendar-range !important;
  }
}

.mdc-list-item__primary-text {
  color: white;
}
// mat-select
.mat-mdc-select-value {
  color: $matFormFieldInputColor !important;

  .mat-mdc-select-placeholder {
    color: $matFormFieldLabelColor;
  }
}
.mat-mdc-select-arrow {
  color: $matFormFieldInputColor !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: $text-brand-primary !important;
}

.mdc-list-item__primary-text {
  color: $body-color !important;
}

// checkbox
.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: $body-color;

  a {
    color: $body-color;
  }
}
.mdc-checkbox .mdc-checkbox__native-control ~ .mdc-checkbox__background {
  border-radius: 4px;
}
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  background-color: $bg !important;
  border-color: $text-tertiary !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background-color: $brand-primary !important;
  border-color: $brand-primary !important;
}
.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: $text-on-primary !important;
}

.community-view {
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background {
    background-color: $notificationSuccess !important;
    border-color: $notificationSuccessText !important;
  }
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    color: $notificationSuccessText !important;
  }
}

.mat-mdc-checkbox.ng-invalid.ng-touched {
  .mdc-label {
    color: $danger;
    a {
      color: $danger;
    }
  }
}

.tel-input-field {
  .mdc-text-field--outlined {
    padding-left: 0;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding: 0;
  }
}

.normal-dialog.overlay-theme {
  .mdc-text-field--outlined {
    background-color: $matFormFieldBackgroundColor;
    // background-color: $white;
  }
}

.overlay-theme {
  .mdc-text-field--outlined {
    background-color: $white;
  }

  mat-form-field.ng-invalid.ng-touched {
    animation: none;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;

    .mat-mdc-form-field-error {
      font-size: 13px;
      line-height: 2;
    }
  }

  .mat-error-outside {
    margin-top: -20px;
  }

  .flex-inputs-cont {
    display: flex;
    column-gap: 20px;
    margin-bottom: 20px;
  }

  .select-or-add-cont {
    display: flex;
    align-items: center;
  }

  .flex-input {
    width: auto;
    padding: 0;

    &.mat-mdc-form-field {
      margin: 0;

      &.ng-invalid.ng-touched {
        animation: none;
      }
    }

    .mat-mdc-text-field-wrapper {
      height: 50px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }

    &.w-100 {
      width: 100%;
    }
  }

  .flex-half {
    flex: 1 0 50%;
  }

  .flex-half-shrink {
    flex: 1 1 50%;
  }

  .half-field {
    width: calc(50% - 10px);
  }

  .file-container {
    margin-bottom: 20px;
  }

  .template-asset-select-cont {
    margin-bottom: 20px;
  }

  app-template-asset-select .image-preview-component {
    margin-bottom: 20px;
  }

  .create-step-header {
    background-color: $overlay-bg !important;
  }
}

.filter-select {
  margin-bottom: 0;
  border-radius: 25px !important;

  .mdc-text-field--outlined {
    background-color: transparent;
  }
  &.mat-focused .mdc-text-field--outlined,
  &.mat-mdc-form-field.has-value .mdc-text-field--outlined {
    background-color: $bg-secondary;
  }

  .mdc-notched-outline > * {
    transition: border-color 150ms ease-in-out;
    border-color: $border-color !important;
  }
  .mdc-text-field--focused {
    .mdc-notched-outline {
      > * {
        border-color: $matFormFieldFocusedBorderColor !important;
      }

      .mdc-notched-outline__notch {
        border-top: 2px solid !important;
        border-color: $matFormFieldFocusedBorderColor !important;
      }
    }
  }
  .mdc-text-field--invalid {
    .mdc-notched-outline {
      > * {
        border-color: $danger !important;
      }

      .mdc-notched-outline__notch {
        border-top: 1px solid !important;
        border-color: $danger !important;
      }
    }
  }

  &:hover {
    .mdc-notched-outline > * {
      border-color: $matFormFieldFocusedBorderColor !important;
    }
  }

  .mdc-text-field--outlined {
    border-radius: 25px !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 25px 25px 0 !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 25px 0 0 25px !important;
    width: 25px !important;
  }
  .mat-mdc-form-field-flex {
    height: 35px;
    align-items: center;
  }

  .mat-mdc-form-field-infix {
    width: 106px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  .mat-mdc-form-field-icon-suffix {
    padding: 0 6px 0 4px;
    color: $matFormFieldInputColor;
  }

  .mat-mdc-form-field-flex
    .mdc-notched-outline
    .mdc-notched-outline__notch
    label {
    top: 50%;
  }

  .mat-mdc-select-trigger {
    gap: 0.5rem;
  }

  .filter-select-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;

    .filter-select-trigger-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .filter-select-badge {
      display: flex;
      width: 20px;
      height: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      flex-shrink: 0;

      border-radius: 50px;
      background-color: $brand-primary;

      @extend .p1;
      color: $text-on-primary;
      text-align: center;
    }
  }
}

.action-select {
  margin-bottom: 0;
  border-radius: 6px !important;

  .mdc-text-field--outlined {
    border-radius: 6px !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 6px 6px 0 !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 6px 0 0 6px !important;
    width: 6px !important;
  }
  .mat-mdc-form-field-flex {
    height: 35px;
    align-items: center;
  }

  .mat-mdc-form-field-infix {
    width: 72px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  .mat-mdc-form-field-icon-suffix {
    padding: 0 6px 0 4px;
    color: $matFormFieldInputColor;
  }

  .mat-mdc-form-field-flex
    .mdc-notched-outline
    .mdc-notched-outline__notch
    label {
    top: 50%;
  }
}

.table-action-icon {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 50px;
  border: 1px solid $border-color;
  background: $bg-secondary;

  color: $text-primary;

  cursor: pointer;

  > i {
    width: 16px;
    height: 16px;
  }

  transition: all 150ms ease-in-out;

  &:hover {
    border: 1px solid $brand-secondary;
    color: $brand-secondary;
  }
}

.table-select {
  margin-bottom: 0;

  .mdc-text-field--outlined {
    background-color: transparent;
    padding-left: 0;
  }
  &.mat-focused .mdc-text-field--outlined,
  &.mat-mdc-form-field.has-value .mdc-text-field--outlined {
    background-color: transparent;
  }

  .mat-mdc-select-value {
    color: $body-color !important;
  }
  .mat-mdc-select-arrow {
    color: $body-color !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
    border: none !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
    border: none !important;
    width: 0 !important;
  }
  .mat-mdc-form-field-flex {
    height: 35px;
    align-items: center;
  }

  .mat-mdc-form-field-infix {
    width: 106px;
    color: $body-color;
  }

  .mat-mdc-select-placeholder {
    color: $grey-979797;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  .mat-mdc-form-field-flex
    .mdc-notched-outline
    .mdc-notched-outline__notch
    label {
    top: 50%;
  }
}

.suffix-select {
  margin-bottom: 0;
  padding: 0 12px 0 4px;

  .mdc-text-field--outlined {
    background-color: transparent;
    padding-left: 0;
  }
  &.mat-focused .mdc-text-field--outlined,
  &.mat-mdc-form-field.has-value .mdc-text-field--outlined {
    background-color: transparent;
  }

  .mat-mdc-select-value {
    color: $body-color !important;
  }
  .mat-mdc-select-arrow {
    color: $body-color !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
    border: none !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
    border: none !important;
    width: 0 !important;
  }
  .mat-mdc-form-field-flex {
    height: 35px;
    align-items: center;
  }

  .mat-mdc-form-field-infix {
    width: 57px;
    color: $body-color;
  }

  .mat-mdc-select-placeholder {
    color: $grey-979797;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  .mat-mdc-form-field-flex
    .mdc-notched-outline
    .mdc-notched-outline__notch
    label {
    top: 50%;
  }
}

.select-centered-options {
  .mat-mdc-option .mdc-list-item__primary-text {
    margin: auto;
  }
}

.table-field {
  margin-bottom: 0;
  border-radius: 6px !important;

  .mdc-text-field--outlined {
    border-radius: 6px !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 6px 6px 0 !important;
  }

  .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 6px 0 0 6px !important;
    width: 6px !important;
  }
  .mat-mdc-form-field-flex {
    height: 35px;
    align-items: center;
  }

  .mat-mdc-form-field-infix {
    width: 106px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  .mat-mdc-form-field-icon-suffix {
    padding: 0 6px 0 4px;
    color: $matFormFieldInputColor;
  }

  .mat-mdc-form-field-flex
    .mdc-notched-outline
    .mdc-notched-outline__notch
    label {
    top: 50%;
  }
}

// custom radio
.custom-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.custom-radio-button {
  .mdc-form-field {
    display: flex;
    padding: 0.5rem 0.25rem;
    align-items: flex-start;
    gap: 0.625rem;

    .mdc-radio {
      padding: 0;
    }

    .custom-radio-title {
      @extend .h7;
      color: $text-primary;
    }

    .custom-radio-desc {
      @extend .p1;
      color: $text-tertiary;
    }
  }

  &.disabled {
    .mdc-form-field {
      .custom-radio-title {
        color: $text-tertiary;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .overlay-theme {
    .flex-inputs-cont {
      flex-direction: column;
      margin-bottom: 0;
    }
    .flex-input {
      &.mat-mdc-form-field {
        margin-bottom: 20px;
      }
    }

    .half-field {
      width: 100%;
    }
  }
}
