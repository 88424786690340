@import 'scss/colors';

.slide-toggle-cont {
  display: flex;
  align-items: center;
  gap: 13px;

  .slide-toggle-label {
    background-color: $white;
    border-radius: 12px;
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
}
.toggle-content {
  display: flex;
  justify-content: space-between;
}

.toggle-check {
  .toggle-check-input {
    width: 1px;
    height: 1px;
    position: absolute;

    &:checked {
      ~ .toggle-check-text {
        background: $brand-primary;
        color: $text-on-primary;
        padding-left: 0.5em;
        padding-right: 2em;

        // &:before {
        //   content: 'Yes';
        //   padding-left: 5px;
        // }

        &:after {
          left: 100%;
          margin-left: -36px;
          background: $text-on-primary;
        }
      }
    }
  }

  .toggle-check-text {
    display: flex;
    align-items: center;
    position: relative;
    background: $text-secondary;
    padding: 0.25em 0.5em 0.25em 2em;
    border-radius: 40px;
    min-width: 40px;
    min-height: 32px;
    color: $white;
    cursor: pointer;
    transition: background-color 0.2s;

    font-size: 14px;
    line-height: 1.79;

    &:after {
      content: ' ';
      display: block;
      background: $bg;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      position: absolute;
      left: 0.3em;
      top: 0.25em;
      transition:
        left 0.2s,
        margin-left 0.2s,
        background-color 0.2s;
    }

    // &:before {
    //   content: 'No';
    //   padding-left: 14px;
    // }
  }

  &.toggle-check-small {
    .toggle-check-input {
      width: 1px;
      height: 1px;
      position: absolute;

      &:checked {
        ~ .toggle-check-text {
          background: $brand-primary;
          color: $text-on-primary;
          padding-left: 0.5em;
          padding-right: 2em;

          // &:before {
          //   content: 'Yes';
          //   padding-left: 5px;
          // }

          &:after {
            left: 100%;
            margin-left: -25px;
            background: $text-on-primary;
          }
        }
      }
    }

    .toggle-check-text {
      display: flex;
      align-items: center;
      position: relative;
      background: $text-secondary;
      padding: 0.25em 0.5em 0.25em 2em;
      border-radius: 40px;
      min-width: 30px;
      min-height: 24px;
      color: $white;
      cursor: pointer;
      transition: background-color 0.2s;

      font-size: 10px;
      line-height: 1.79;

      &:after {
        content: ' ';
        display: block;
        background: $bg;
        width: 22px;
        height: 22px;
        border-radius: 32px;
        position: absolute;
        left: 0.3em;
        top: 0.3em;
        transition:
          left 0.2s,
          margin-left 0.2s,
          background-color 0.2s;
      }

      // &:before {
      //   content: 'No';
      //   padding-left: 11px;
      // }
    }
  }
}

@media only screen and (min-width: 769px) {
  .slide-toggle-cont {
    .slide-toggle-label {
      min-width: 317px;
    }
  }
}

// toggle container
.toggle-container {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 216px;
  border-radius: 50px;
  background-color: rgba(161, 161, 161, 0.1);
  color: rgba(92, 92, 92, 0.5);
  box-sizing: border-box;
  padding: 0;
  font-size: 14px;
  line-height: 1.42;
  box-shadow: inset 0 0 0 1px $border-color;

  .toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 98px;
    flex: 1;
    position: relative;
    z-index: 1;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    transition:
      background-color 0s,
      color 0.3s;
  }

  .toggle-button.active {
    background-color: $brand-primary;
    color: $text-on-primary;
    border-radius: 50px;
  }

  .toggle-button:not(.active) {
    color: #a1a1a1;
  }
}

@media only screen and (max-width: 600px) {
  .toggle-container {
    width: 100%;
  }
}
