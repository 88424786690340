@import './colors';
@import './typography';
@import './_slide-toggle';
@import './buttons';
@import './eventpage-material-theme';
@import './toastr';
@import './_overlay-page';
@import './_add-overlay-page';
@import './_loader';
@import './header';
@import './select-or-hover';
@import './_dialogs';
@import './action-buttons';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

@import 'tippy.js/dist/tippy.css';

/* Global Styles */

* {
  touch-action: pan-x pan-y;
  overscroll-behavior: none;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  color: $text-dark;
  background-color: $body-bg;
  font-family: 'Gabarito', sans-serif !important;
  font-weight: normal !important;
  font-size: 16px;
}

.page-container {
  padding: 22px 96px 94px 96px;
}

.display-none {
  display: none !important;
}

.icon-big {
  font-size: 36px;
  line-height: 1.11;
}

.icon-small {
  font-size: 14px;
  line-height: 1.14;
}

.icon-regular {
  font-size: 20px;
  line-height: 1.2;
}

.w-100 {
  width: 100%;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-bottom: 20px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.text-link {
  font-size: 16px;
  color: $text-brand-primary;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    filter: brightness(90%);
  }

  &.text-link-grey-darker {
    color: $text-grey-darker;
  }
}

.hint-message-link {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  cursor: pointer;
  text-decoration: none;

  @extend .h6;
  color: $text-brand-primary;

  transition: color 150ms ease-in-out;

  &:hover {
    color: $brand-secondary;
  }
}

.hr-text {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $text-dark;
  line-height: 0.1em;
  margin: 30px 0 32px;
  font-size: 14px;

  > span {
    background: $body-bg;
    padding: 0 10px;
  }

  &.hr-text-internal > span {
    background: $internal-body-bg;
  }
}

.time-range-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.display-text-input {
  border: none;
  font-size: 10px;
  color: $eventThemePrimaryColor;
  background: transparent;
  letter-spacing: 1.3px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Gabarito';
  width: auto;
  border-bottom: 1px solid transparent;

  &:focus {
    outline: none;
    border-bottom: 1px solid $grey-979797;
  }
}

.display-text-input-size {
  position: absolute;
  white-space: nowrap;
  font-size: 10px;
  letter-spacing: 1.3px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Gabarito';
  width: auto;
  height: auto;
}

@media screen and (max-width: 768px) {
  .page-container {
    padding: 79px 24px 35px 24px;
  }

  .desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .mobile-only {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .min-992-only {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .max-991-only {
    display: none !important;
  }
}

.x-mark-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%); // Center the icon vertically
  color: $text-dark;
  cursor: pointer;
}

.header-container {
  padding: 13px 20px 0 20px;
}

.list-page-container {
  padding: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

// flexbox
.d-flex {
  display: flex;
}
.gap-20 {
  gap: 20px;
}
.justify-content-center {
  justify-content: center;
}

// event edit form
.event-edit-input,
.event-edit-textarea {
  // font-family: $primaryFontFamily;
  box-sizing: border-box;
  width: 100%;
  padding: 6px;
  color: $eventThemeBodyFontColor;
  border: none;
  border-radius: 4px;
  resize: none;

  background-color: $event-edit-field-bg-color;

  transition: all 100ms ease-in;

  &::placeholder {
    color: $eventThemeBodyFontColor;
  }

  &.primary-font {
    color: $eventThemePrimaryColor;

    &::placeholder {
      color: $eventThemePrimaryColor;
    }
  }

  &:hover,
  &:focus {
    // background-color: $eventThemePrimaryColor;
    color: $eventThemeActionColor;
    border: none;
    outline: none !important;
    padding: 6px;

    &::placeholder {
      color: $eventThemeActionColor;
    }
  }
}

.textarea-charcount-wrapper {
  position: relative;

  .event-edit-textarea::after {
    content: '';
    display: block;
    height: 1.2em;
  }

  &:hover,
  &:focus,
  &:active {
    color: $eventThemeBodyFontColor;
    .char-counter {
      color: $eventThemeBodyFontColor;
    }
  }

  .char-counter {
    position: absolute;
    right: 12px;
    bottom: 8px;
    color: $eventThemeBodyFontColor;
    &:hover {
      color: $eventThemeBodyFontColor;
    }
  }
}

.textarea-w-counter .mat-mdc-form-field-text-suffix {
  align-self: flex-end;
  padding-bottom: 0.75rem;
  color: $text-tertiary;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.33;
}

// toastr
@media screen and (max-width: 768px) {
  .toast-container {
    box-sizing: border-box;
    padding: 0 18px;
    width: 100%;
    display: flex;
    justify-content: center;

    right: unset !important;

    .ngx-toastr {
      width: 100% !important;
      max-width: 320px !important;
    }
  }
}

.toast-success {
  background-color: $notificationSuccess;
  background-image: none;
  padding: 15px 35px 15px 15px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  color: $notificationSuccessText !important;
  width: 320px !important;

  .toast-close-button {
    position: absolute;
    top: 13px;
    right: 15px;
    color: $notificationSuccessText;

    &:hover {
      color: $white;
    }
  }

  &.toast-success-white-primary {
    background-color: $bg;
    color: $brand-primary !important;

    .toast-close-button {
      color: $brand-primary !important;
      &:hover {
        color: $brand-primary !important;
      }
    }
  }

  &.toast-success-white-event-primary {
    background-color: $eventThemePrimaryTextColor;
    color: $eventThemePrimaryColor !important;

    .toast-close-button {
      color: $eventThemePrimaryColor !important;
      &:hover {
        color: $eventThemePrimaryColor !important;
      }
    }
  }
}

.mat-mdc-menu-panel {
  background-color: $body-bg !important;
  border: 1px solid $border-color;
  border-radius: 6px;

  .mat-mdc-menu-item {
    color: $body-color;
  }

  .mat-mdc-menu-item:not([disabled]):hover {
    background: $selected-action !important;
  }
}

.cdk-overlay-connected-position-bounding-box {
}
.mat-menu-actions-container {
  &.mat-mdc-menu-panel {
    box-shadow: none;
    border-radius: 6px;
    width: fit-content;
    overflow: unset;
    margin: 5px 0 10px !important;

    .mat-mdc-menu-content {
      padding: 0;

      .mat-mdc-menu-item {
        margin: 0;
        padding: 12px 16px !important;
        height: 36px !important;
        list-style-type: none;

        &:first-child {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        &:last-child {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        .menu-item-content {
          display: flex;
          align-items: center;

          .far {
            margin-right: 11px;
          }

          &.delete-item {
            color: $danger;
          }
        }

        .mat-mdc-menu-item-text {
          font-family: 'Gabarito', sans-serif;
          font-size: 14px;
          line-height: 1.42;
        }
      }
    }
  }
}

.mat-menu-actions-blur-container {
  &.mat-mdc-menu-panel {
    background-color: rgba(22, 15, 18, 0.15) !important;
    backdrop-filter: blur(68px);
    margin-right: 10px;
    border: none;

    .mat-mdc-menu-content {
      padding: 0;

      .mat-mdc-menu-item {
        color: $white;

        &:not([disabled]):hover {
          background-color: $action-bar-hover !important;
        }
      }
    }
  }
}

.mat-menu-actions-container.mat-menu-comment-edit-container {
  &.mat-mdc-menu-panel {
    width: 115px;

    .mat-mdc-menu-content {
      .mat-mdc-menu-item {
        height: 30px !important;

        .mat-mdc-menu-item-text {
          font-family: 'Gabarito', sans-serif;
          font-size: 14px;
          line-height: 1.79;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .mat-menu-actions-container.mat-menu-template-preview {
    &.mat-mdc-menu-panel {
      width: 195px;
    }
  }
}

.show-password-suffix {
  color: $matFormFieldLabelColor;
  padding-right: 14px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.no-overflow-x-cont {
  overflow-x: hidden;
}

.mdc-tooltip__surface {
  text-align: center !important;
  background-color: $const-dark-color !important;
  color: $white !important;
  font-size: 14px !important;
  line-height: 1.3 !important;
  font-family: 'Gabarito', sans-serif !important;
  max-width: 400px !important;
}

.unmute-tooltip {
  .mdc-tooltip__surface {
    text-align: center !important;
    background-color: $const-dark-color !important;
    color: $white !important;
    line-height: 1.3 !important;
    font-family: 'Gabarito', sans-serif !important;
  }
}

.menu-item-tooltip {
  right: -11px;
  .mdc-tooltip__surface {
    padding: 8px 10px;
    text-align: center !important;
    background-color: $bg-tertiary !important;
    color: $text-primary !important;
    line-height: 1.3 !important;
    font-family: 'Gabarito', sans-serif !important;
  }
}

.table-text-editable {
  &:empty::before {
    color: $grey-979797;
  }
  &:empty {
    &:hover,
    &:focus {
      &::before {
        color: $grey-979797 !important;
      }
    }
  }
}

.cdk-drag-preview {
  overflow: hidden;
}

.ngx-colors-no-accept {
  .nav-wrapper {
    button:last-child {
      display: none;
    }
  }
}

// cookieconsent
body {
  .cc-window.cc-floating {
    border: 1px solid $border-color;
    border-radius: 6px;

    .cc-btn.cc-allow:hover,
    .cc-btn.cc-allow:focus {
      background-color: $brand-primary;
      filter: brightness(90%);
    }
  }

  .cc-animate.cc-revoke.cc-bottom {
    -webkit-transform: translateY(2.45em);
    transform: translateY(2.45em);
  }

  .cc-revoke {
    display: none;
  }
}

// ccm
.ccm-root .ccm-settings-summoner {
  display: none !important;
}

// input autocomplete fill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: $matFormFieldInputColor;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px rgba(80, 80, 80, 0.1);
}

// hide number input arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
